import React, { useState } from "react";
import { Link } from "react-router-dom";

import "swiper/scss";

import data from "../../assets/fake-data/dataPartner";

function Partner(props) {
  const [dataBlock] = useState({
    subheading: "We Are Partnered with Top Brands",
    heading: "Our Partners",
  });

  return (
    <section className="team">
      <div className="container">
        <div className="row">
          <div className="block-text center">
            <h6 className="sub-heading">
              <span>{dataBlock.subheading}</span>
            </h6>
            <h3 className="heading pd">{dataBlock.heading}</h3>
          </div>
          <div
            // className="brands block-text center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            {/* <Swiper
              className="brands-swiper"
              spaceBetween={30}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                500: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 4,
                },
                991: {
                  slidesPerView: 6,
                },
              }}
              loop={true}
            > */}
            {data.map((idx) => (
              // <SwiperSlide key={idx.id}>
// <<<<<<< master
//               <Link to="#">
//                 <img
//                   src={idx.img}
//                   alt="Cyfonii"
//                   style={{
//                     width: idx.width,
//                     height: idx.height,
//                     marginLeft: 30,
//                     marginRight: 30,
//                     marginTop: 30,
//                     marginBottom: 30,
//                   }}
//                 />
//               </Link>
// =======
              <div
                style={{
                  width: 290,
                  height: 200,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                  marginLeft: 30,
                  marginRight: 30,
                  marginTop: 30,
                  marginBottom: 30,
                }}
              >
                <div
                  style={{
                    width: 150,
                    height: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={idx.img}
                    alt="Cyfonii"
                    style={{
                      width: idx.width,
                      height: idx.height,
                    }}
                  />
                </div>
                <div
                  style={{
                    width: 140,
                    height: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ textAlign: "center" }}>{idx.text}</p>
                </div>
              </div>
              // </SwiperSlide>
            ))}
            {/* </Swiper> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner;
