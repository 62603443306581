
import img1 from '../images/layouts/team-01.jpg'
import img2 from '../images/layouts/team-02.jpg'
import img3 from '../images/layouts/team-03.jpg'
import img4 from '../images/layouts/team-04.jpg'
import img5 from '../images/layouts/team-05.jpg'
import img6 from '../images/layouts/team-06.jpg'

const dataTeam = [
    {
        id: 1,
        name: 'Upeksha Erandi',
        role: 'Chairperson of IEEE WIE Affinity Group University of Moratuwa',
        phone: '+94 76 218 3500',
        email: 'upekshaerandi35@gmail.com',
        img: img1
    },
    {
        id: 2,
        name: 'Vishwani Bhagya',
        role: 'Vice-Chairperson of IEEE WIE Affinity Group University of Moratuwa',
        phone: '+94 76 921 4918',
        email: 'bhagyalgv@gmail.com',
        img: img2
    },
    {
        id: 3,
        name: 'Dilini De Silva',
        role: 'Secretary of IEEE WIE Affinity Group University of Moratuwa, Finance Lead of HackElite 1.0',
        phone: '+94 71 368 1652',
        email: 'dilinidesilva2021@gmail.com',
        img: img3
    },
    {
        id: 4,
        name: 'Poorni Denipitiya',
        role: 'HackElite 1.0 Project Co-chairperson',
        phone: '+94 77 210 7834',
        email: 'denipitiyadpu.21@uom.lk',
        img: img4
    },
    {
        id: 5,
        name: 'Nilupulee Amarathunga',
        role: 'HackElite 1.0 Project Co-chairperson',
        phone: '+94 76 281 1062',
        email: 'nilupuleeamarathunga@gmail.com',
        img: img5
    },
    {
        id: 6,
        name: 'Janeesha Wickramasinghe',
        role: 'HackElite 1.0 Project Co-chairperson',
        phone: '+94 77 621 2186',
        email: 'wickramasinghejj.21@gmail.com',
        img: img6
    }
]

export default dataTeam;
