import React, { useState } from "react";
import { GiAwareness } from "react-icons/gi";
import { HiLightBulb } from "react-icons/hi";
import { GrWorkshop } from "react-icons/gr";

function Speciality(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "Sessions",
    heading: "Workshops ",
    desc: "",
  });
  return (
    <section className="speciality" id="sessions">
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading pd">{dataBlock.heading}</h3>
              <p className="">{dataBlock.desc}</p>
            </div>
          </div>
          {data.map((idx) => (
            <div key={idx.id} className="col-xl-4 col-md-4">
              <div className="speciality-box">
                <div className="icon">
                  {/* <img src={idx.img} alt="Cyfonii" /> */}
                  {idx.id === 1 && (
                    <GiAwareness style={{ color: "#fff", fontSize: 50 }} />
                  )}
                  {idx.id === 2 && (
                    <HiLightBulb style={{ color: "#fff", fontSize: 50 }} />
                  )}
                  {idx.id === 3 && (
                    <GrWorkshop style={{ color: "#fff", fontSize: 50 }} />
                  )}
                </div>
                <h5 className="title">{idx.title}</h5>
                <p>{idx.desc}</p>
                <h3 className="number">0{idx.id}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Speciality;
