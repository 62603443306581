
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'

const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'QUIZ',
        desc: "The quiz aims to evaluate participants' knowledge in key areas including mathematics, electronics, IT, computer science, robotics, and artificial intelligence. "
    },
    {
        id: 2,
        img: img2,
        title: 'IDEATHON',
        desc: 'The place where the participants are assigned a specific task and collaborate to build a viable solution, guided by a knowledge partner.'
    },
    {
        id: 3,
        img: img3,
        title: 'GRAND FINALE',
        desc: "Finalists must complete two major tasks: proposing candidates' ideas to an expert panel. "
    },

]

export default dataPortfolio;