

const dataRoadMap = [

    {
        id: 1,
        time: 'Step 01',
        title: 'Registrations  opening',
        desc: 'Participants can begin signing up for the event.',
        class: ''
    },
    {
        id: 2,
        time: 'Step 02',
        title: 'Awareness Session (Workshop 1)',
        desc: 'An introductory workshop to provide participants with essential information and context about the event.',
        class: 'right'
    },

    {
        id: 3,
        time: 'Step 03',
        title: ' Registration Closing',
        desc: 'The registration period ends; no further participants can sign up.',
        class: ''
    },

    {
        id: 4,
        time: 'Step 04',
        title: 'Phase 1: Quiz',
        desc: "Participants take a quiz to assess their understanding and knowledge relevant to the event's theme.",
        class: 'right'
    },
    {
        id: 5,
        time: 'Step 05',
        title: 'Results Release',
        desc: 'The results of the Phase 1 quiz are announced, and participants are informed of their progress.',
        class: ''
    },
    {
        id: 6,
        time: 'Step 06',
        title: 'Phase 2: Ideathon',
        desc: 'Participants are given a problem statement and are required to submit their solutions.',
        class: 'right'
    },
    {
        id: 7,
        time: 'Step 07',
        title: 'Phase 2 (Opening Submission)',
        desc: 'Participants submit their initial ideas or projects for evaluation.',
        class: ''
    },
    {
        id: 8,
        time: 'Step 08',
        title: 'Closing Submission',
        desc: 'The submission window for Phase 2 closes; no further entries will be accepted.',
        class: 'right'
    },
    {
        id: 9,
        time: 'Step 09',
        title: 'Session-Idea Pitching (Workshop 3)',
        desc: 'A workshop focused on refining ideas and pitching techniques to help participants prepare for presenting their projects.',
        class: ''
    },
    {
        id: 10,
        time: 'Step 10',
        title: 'Finalists Announcement',
        desc: 'The top participants or teams are announced, who will move on to the final phase.',
        class: 'right'
    },
    {
        id: 11,
        time: 'Step 11',
        title: 'Phase 3 - Grand Finale (Pitching, & Award Ceremony)',
        desc: 'Finalists present their pitches, and the event concludes with an award ceremony.',
        class: ''
    }


]

export default dataRoadMap;