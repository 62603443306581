import React, { useState } from "react";
import PropTypes from "prop-types";

import img from "../../assets/images/layouts/portfolio3.jpg";

Portfolio.propTypes = {
  data: PropTypes.array,
};

function Portfolio(props) {
  const { data } = props;

  // Define the base style
  const baseStyle = {
    borderRadius: "20px",
    objectFit: "cover",
  };

  // Define the style for smaller screens
  const smallScreenStyle = {
    ...baseStyle,
    width: 250, // Smaller width for smaller screens
    height: 250, // Smaller height for smaller screens
  };

  // Use a function or a component method to determine the screen size and adjust the style
  const getStyleForScreen = () => {
    if (window.innerWidth < 768) {
      // Example breakpoint at 768px
      return smallScreenStyle;
    }
    return {
      ...baseStyle,
      width: 400,
      height: 400,
    };
  };

  const [dataBlock] = useState({
    subheading: "Tasks",
    heading: "Event Phases",
  });
  return (
    <section className="portfolio" id="tasks">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading pd">{dataBlock.heading}</h3>
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="portfolio__left">
              {data.map((idx) => (
                <div key={idx.id} className="portfolio-box">
                  <div className="step">TASK {idx.id}</div>
                  <div className="icon">
                    <img src={idx.img} alt="Cyfonii" />
                  </div>
                  <div className="content">
                    <h5 className="title">{idx.title}</h5>
                    <p>{idx.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-xl-6 col-md-6">
            <div className="portfolio__right">
              <div
                className="image"
                data-aos="fade-left"
                data-aos-duration="2000"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={img} alt="cyfonii" style={getStyleForScreen()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
