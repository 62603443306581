import React, { useState } from "react";

import img1 from "../../assets/images/layouts/lay1.png";

function About(props) {
  const [dataImg] = useState([
    {
      id: 1,
      img: img1,
      class: "img1",
    },
  ]);

  const [dataBlock] = useState({
    subheading: "About Hackathon",
    heading: "What is HACKELITE 1.0?",
    desc1:
      "HackElite 1.0 is an invaluable competition consisting of an ideathon and hackathon, hosted by the IEEE Women in Engineering Affinity Group of University of Moratuwa. Our objective is to reach out to female students from all universities in Sri Lanka and provide them with an exceptional opportunity centered around areas such as mathematics, electronics, computer science, artificial intelligence, coding skills, problem-solving skills, communication skills, professionalism, and analytical thinking while empowering them with a unique experience. It will help to enable them to seize forthcoming opportunities.",
    desc2:
      "During the event, experts will conduct informative sessions covering the essential knowledge needed to excel in today's industry. Through engaging in these tasks, attendees will acquire valuable insights into many professional and technical skills. The event will also provide a platform for participants to showcase their talents and skills, as well as to network with industry professionals.",
  });
  return (
    <section className="about">
      <div className="shape"></div>
      <div className="container">
        <div className="row rev">
          <div className="col-xl-6 col-md-12">
            <div className="about__right">
              <div className="images">
                {dataImg.map((idx) => (
                  <img
                    key={idx.id}
                    className={idx.class}
                    src={idx.img}
                    alt="cyfonii"
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="block-text">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
              <p className="mb-17">{dataBlock.desc1}</p>
              <p className="mb-26">{dataBlock.desc2}</p>
              {/* <Button link="/about" title="More About Us" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
