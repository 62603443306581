
import img1 from '../images/logo/brand-01.png'
import img2 from '../images/logo/brand-02.png'
import img3 from '../images/logo/brand-03.png'
import img4 from '../images/logo/brand-04.png'
import img5 from '../images/logo/brand-05.png'


const dataPartner = [

    {
        id: 1,
        img: img1,
        text: "Official Knowledge Partner",
        width: 150,
        height: 90,
    },
    {
        id: 2,
        img: img2,
        text: "Official Gift Partner",
        width: 150,
        height: 45,
    },
    {
        id: 3,
        img: img3,
        text: "Official Technology Partner",
        width: 150,
        height: 75,
    },
    {
        id: 4,
        img: img4,
        text: "Official Gift Partner",
        width: 120,
        height: 120,
    },
    {
        id: 5,
        img: img5,
        text: "Digital platform partner",
        width: 120,
        height: 120,
    },
]

export default dataPartner;