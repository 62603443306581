import img1 from "../images/icon/icon1.png";
import img2 from "../images/icon/icon2.png";
import img3 from "../images/icon/icon3.png";

const dataBox = [
  {
    id: 1,
    img: img1,
    title: "Awareness session (2024.07.27)",
    desc: "In this session, we intend to give a brief introduction to HackElite, as well as the Hackathon and Ideathon. We believe it will benefit newbies to this type of competition. ",
  },
  {
    id: 2,
    img: img2,
    title: "Task release session for Ideathon",
    desc: "The knowledge partner will lead this session, and we expect to give a clear understanding of what they expect from the candidates throughout the ideathon. ",
  },
  {
    id: 3,
    img: img3,
    title: "Workshop about how to do a winning pitch",
    desc: "The workshop on how to deliver a winning pitch teaches participants to present their ideas persuasively. This workshop aims to build confidence and ensure presentations are engaging and convincing.",
  },
];

export default dataBox;
