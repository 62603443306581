import React from "react";
import dataBox from "../assets/fake-data/data-box";
import dataFaqs from "../assets/fake-data/data-faq";
import dataPortfolio from "../assets/fake-data/data-portfolio";
import dataRoadMap from "../assets/fake-data/data-roadmap";
import dataTeam from "../assets/fake-data/dataTeam";
import About from "../components/about/About";
import Banner from "../components/banner/Banner";
import Faqs from "../components/faqs/Faqs";
import Footer from "../components/footer/Footer";
import Portfolio from "../components/portfolio/Portfolio";
import Roadmap from "../components/roadmap/Roadmap";
import Speciality from "../components/speciality/Speciality";
import Team from "../components/team/Team";
import Partner from "../components/partner/Partner";

function Home01(props) {
  return (
    <div className="home-1 wrapper">
      <Banner />

      <About />

      <Speciality data={dataBox} />

      <Portfolio data={dataPortfolio} />

      <Roadmap data={dataRoadMap} />

      <Team data={dataTeam} />

      <Partner />

      <Faqs data={dataFaqs} />

      <Footer />
    </div>
  );
}

export default Home01;
