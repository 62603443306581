import React from "react";
import { Link } from "react-router-dom";
import { FaCalendarCheck } from "react-icons/fa";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import img1 from "../../assets/images/layouts/banner.png";

function Banner(props) {
  return (
    <section className="banner">
      <div className="shape right"></div>
      <div className="container big">
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <h2 className="heading">
                  Explore Your <br />
                  Creativity with{" "}
                  <span className="arlo_tm_animation_text_word">HackElite</span>
                </h2>
                <p className="desc">
                  Join with the leading hackathon to elevate your standards of
                  creativity and innovation
                </p>

                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSf2TLxqzpWs3nA0wxkbpU3j4SH60wESFJj7SASpyhlTbcSHKg/viewform?usp=sf_link"
                  className="action-btn"
                  style={{ marginLeft: 10, marginTop: 10 }}
                >
                  <span>Register Now</span>
                </a>
                <a
                  href="https://drive.google.com/file/d/1Hbv0RniTUqkHYocs_AZZ-1t9KWWm6Cdb/view"
                  className="action-btn"
                  style={{ marginLeft: 10, marginTop: 10 }}
                >
                  <span>Delegate Booklet</span>
                </a>
              </div>

              {/* <div className="pay">
                <h6>Powered up by</h6>

                <div className="list">
                  <ul>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-01"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-02"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-03"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-04"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-05">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                          <span className="path4"></span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <span className="icon-logo-06"></span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-xl-6 d-md-block d-none">
            <div className="banner__right">
              <div className="image">
                <img src={img1} alt="cyfonii" />
              </div>

              <div className="price">
                <div className="icon">
                  <FaCalendarCheck
                    style={{ color: "#9520f8", width: 40, height: 40 }}
                  />
                </div>
                <div className="content">
                  <p>Registration Open</p>
                  <h5>2024-07-17</h5>
                </div>
              </div>

              <div className="owner">
                <div className="icon">
                  <FaCalendarCheck
                    style={{ color: "#9520f8", width: 40, height: 40 }}
                  />
                </div>
                <div className="content">
                  <p>Registration Deadline</p>
                  <h5>2024-08-30</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
