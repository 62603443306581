const dataFaqs = [
    {
        id: 1,
        title: 'How many people can be in a team?',
        text: 'A team can consist of a minimum of 3 participants and a maximum of 4 participants.',
        show: ''
    },
    {
        id: 2,
        title: 'Who can participate in HackElite 1.0?',
        text: 'All female undergraduates from any state or private university are eligible to participate.',
        show: ''
    },
    {
        id: 3,
        title: 'Can a team consist of participants from different universities?',
        text: 'No, all team members must be from the same university, as HackElite 1.0 is an inter-university competition. But no need to be the same faculty.',
        show: ''
    },
    {
        id: 4,
        title: 'Is this competition open only to IEEE members?',
        text: 'No, the competition is open to all eligible participants, not just IEEE members.',
        show: ''
    },
    {
        id: 5,
        title: 'What is the evaluation process?',
        text: 'The evaluation process consists of several phases:\n1. Quiz Phase: Participants will first take a quiz.\n2. Ideathon Submission: Successful participants will move on to submit their ideas. (On the final day, you will need to pitch your idea to a panel of judges)\n3. Final Day: Participants who pass the ideathon will be eligible to participate on the final day.\n4. Final Evaluation: The final marks will be calculated based on your team’s overall performance, excluding the Quiz phase marks.',
        show: ''
    },
    {
        id: 6,
        title: 'I have never participated in this kind of competition before. Can I join?',
        text: 'Absolutely! We will organize several workshops to enhance your knowledge and skills.',
        show: ''
    },
    {
        id: 7,
        title: 'Is this competition held physically?',
        text: 'The first phase (Quiz) and the second phase (Ideathon proposal submission) will be conducted online. The final day has been organized as a physical event.',
        show: ''
    },
    {
        id: 8,
        title: 'Do you issue certificates for all participants?',
        text: 'Yes, we issue e-certificates to all participants as recognition of their participation and contribution to the event.',
        show: ''
    },
    {
        id: 9,
        title: 'How can I register for HackElite 1.0?',
        text: 'To register, visit our HackElite 1.0 website and navigate to the registration page. Fill in the required details for all team members to complete the registration process.',
        show: ''
    },
];

export default dataFaqs;
