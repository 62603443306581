import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo/logo.png";

function Footer(props) {
  // const [dataBlock] = useState({
  //   heading: "Get Newsletter",
  //   desc: "Get udpated with news, tips & tricks",
  // });

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <footer className="footer">
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* <div className="footer__main">
                                <div className="block-text center">

                                    <h3 className="heading">{dataBlock.heading}</h3>
                                    <p >{dataBlock.desc}</p>
                
                                        
                                </div>
                                <form action="#" className="form">
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Your email" />
                                        
                                    </div>
                                    <button className="action-btn"><span>Subscribe</span></button>
                                </form>
                            </div> */}

            <div className="footer__bottom">
              <Link to="/" className="logo">
                <img
                  id="site-logo"
                  src={logo}
                  alt="cyfonii"
                  style={{ width: 200, height: 120 }}
                />
              </Link>

              <div className="center mb--30">
                <ul className="list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/#tasks">Tasks</Link>
                  </li>
                  <li>
                    <Link to="/#sessions">Sessions</Link>
                  </li>
                  <li>
                    <Link to="/#timeline">Timelines</Link>
                  </li>
                </ul>
                <p> IEEE Women In Engineering Affinity Group of the University of Moratuwa</p>
              </div>
              <ul className="list-social">
                <li>
                  <Link to="https://www.facebook.com/WIEUOM">
                    <svg
                      width="9"
                      height="16"
                      viewBox="0 0 9 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.57969 9.03953C2.51969 9.03953 1.19969 9.03953 0.599688 9.03953C0.279688 9.03953 0.179688 8.91953 0.179688 8.61953C0.179688 7.81953 0.179688 6.99953 0.179688 6.19953C0.179688 5.87953 0.299688 5.77953 0.599688 5.77953H2.57969C2.57969 5.71953 2.57969 4.55953 2.57969 4.01953C2.57969 3.21953 2.71969 2.45953 3.11969 1.75953C3.53969 1.03953 4.13969 0.559531 4.89969 0.279531C5.39969 0.0995311 5.89969 0.0195312 6.43969 0.0195312H8.39969C8.67969 0.0195312 8.79969 0.139531 8.79969 0.419531V2.69953C8.79969 2.97953 8.67969 3.09953 8.39969 3.09953C7.85969 3.09953 7.31969 3.09953 6.77969 3.11953C6.23969 3.11953 5.95969 3.37953 5.95969 3.93953C5.93969 4.53953 5.95969 5.11953 5.95969 5.73953H8.27969C8.59969 5.73953 8.71969 5.85953 8.71969 6.17953V8.59953C8.71969 8.91953 8.61969 9.01953 8.27969 9.01953C7.55969 9.01953 6.01969 9.01953 5.95969 9.01953V15.5395C5.95969 15.8795 5.85969 15.9995 5.49969 15.9995C4.65969 15.9995 3.83969 15.9995 2.99969 15.9995C2.69969 15.9995 2.57969 15.8795 2.57969 15.5795C2.57969 13.4795 2.57969 9.09953 2.57969 9.03953Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="https://edu.ieee.org/lk-uomwie/">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM4 12C4 11.39 4.08 10.79 4.21 10.22L6.59 12.6C6.64 13.38 6.84 14.13 7.18 14.82L4.93 17.07C4.34 15.83 4 14.45 4 13V12ZM12 20C11.29 20 10.59 19.92 9.92 19.79L12.4 17.31C13.09 17.65 13.84 17.85 14.62 17.9L17.07 20.35C15.83 20.94 14.45 21.28 13 21.28L12 21.28V20ZM12 4C14.45 4 16.83 4.34 18.93 5.07L16.82 7.18C15.13 6.84 13.38 6.64 12.6 6.59L10.22 4.21C10.79 4.08 11.39 4 12 4ZM20 12C20 12.61 19.92 13.21 19.79 13.78L17.41 11.4C17.36 10.62 17.16 9.87 16.82 9.18L19.07 6.93C19.66 8.17 20 9.55 20 11V12ZM7.18 9.18C7.52 9.87 7.72 10.62 7.77 11.4L5.39 13.78C5.08 13.21 5 12.61 5 12C5 11.39 5.08 10.79 5.39 10.22L7.18 9.18ZM12 7.5C14.76 7.5 17 9.74 17 12.5C17 15.26 14.76 17.5 12 17.5C9.24 17.5 7 15.26 7 12.5C7 9.74 9.24 7.5 12 7.5Z"
                        fill="currentColor"
                      />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/company/ieeewieuom/">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.0006 14V8.87249C14.0006 6.35249 13.4581 4.42749 10.5181 4.42749C9.10062 4.42749 8.15563 5.19749 7.77063 5.93249H7.73563V4.65499H4.95312V14H7.85813V9.36249C7.85813 8.13749 8.08563 6.96499 9.59063 6.96499C11.0781 6.96499 11.0956 8.34749 11.0956 9.43249V13.9825H14.0006V14Z"
                        fill="white"
                      />
                      <path
                        d="M0.226562 4.65479H3.13156V13.9998H0.226562V4.65479Z"
                        fill="white"
                      />
                      <path
                        d="M1.68 0C0.7525 0 0 0.7525 0 1.68C0 2.6075 0.7525 3.3775 1.68 3.3775C2.6075 3.3775 3.36 2.6075 3.36 1.68C3.36 0.7525 2.6075 0 1.68 0Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.youtube.com/@ieeewieaffinitygroup-unive9003">
                    <svg
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.2347 1.9639C17.1458 1.22216 16.4468 0.510897 15.7154 0.415609C11.2555 -0.138536 6.7457 -0.138536 2.28731 0.415609C1.55533 0.51069 0.856308 1.22216 0.76739 1.9639C0.452537 4.68236 0.452537 7.31818 0.76739 10.036C0.856308 10.7778 1.55533 11.4897 2.28731 11.5843C6.7457 12.1384 11.2557 12.1384 15.7154 11.5843C16.4468 11.4898 17.1458 10.7778 17.2347 10.036C17.5496 7.31842 17.5496 4.68236 17.2347 1.9639ZM7.58931 8.82375V3.17703L11.8243 6.00049L7.58931 8.82375Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {isVisible && (
        <Link to="#" onClick={scrollToTop} id="scroll-top">
          <span className="icon-arrow-top"></span>
        </Link>
      )}
    </footer>
  );
}

export default Footer;
