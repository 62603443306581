const menus = [
    {
        id: 1,
        name: 'Home',
        links: '#',
    },
    {
        id: 2,
        name: 'Tasks',
        links: '#tasks',
    },
    {
        id: 4,
        name: 'Sessions',
        links: '#sessions',

    },
    {
        id: 5,
        name: 'Timeline',
        links: '#timeline',

    },
]

export default menus;